import { MenuList } from './menu'
import { AppstoreOutlined, DashboardOutlined, UserOutlined, GoldOutlined } from '@ant-design/icons'
export const menuList: MenuList = [
  {
    code: 'dashboard',
    label: 'Dashboard',
    icon: DashboardOutlined,
    path: '/dashboard'
  },
  {
    code: 'company',
    label: 'Company',
    icon: GoldOutlined,
    path: '/dashboard/company'
  },
  {
    code: 'user',
    label: 'User',
    icon: UserOutlined,
    path: '/dashboard/user'
  },
  {
    code: 'platform',
    label: 'Platform',
    icon: AppstoreOutlined,
    path: '/dashboard/platform'
  }
  // {
  //   code: 'permission',
  //   label: 'permission',
  //   icon: 'permission',
  //   path: '/permission',
  //   children: [
  //     {
  //       code: 'routePermission',
  //       label: 'routePermission',
  //       path: '/permission/route'
  //     },
  //     {
  //       code: 'notFound',
  //       label: 'routePermission',
  //       path: '/permission/404'
  //     }
  //   ]
  // }
]
