import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import { setToken } from 'api/fetch'
import _ from 'lodash'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer'

const persistConfig = {
  key: 'root',
  version: 5,
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  type: REHYDRATE,
  effect: async (action, listenerApi) => {
    const token = _.get(action, 'payload.auth.token')
    if (token) setToken(token)
  }
})

export const store = configureStore({
  devTools: true,
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).prepend(listenerMiddleware.middleware)
  }
})

export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
