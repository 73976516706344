import { ConfigProvider, theme as antdTheme } from 'antd'
import { validateMessages } from 'constraints/validateMessages'
import './assets/less/index.less'
import './assets/scss/index.scss'
import RootRoute from './router'

function App() {
  return (
    <ConfigProvider
      form={{ validateMessages: validateMessages }}
      theme={{
        // token: { colorPrimary: '#a2d2ff' },
        algorithm: antdTheme.defaultAlgorithm
      }}
    >
      <RootRoute />
    </ConfigProvider>
  )
}

export default App
