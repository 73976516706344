import fetch from './fetch'

const preUrl = '/cherwell'

export function uploadEsign(uuid: string, file: File) {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('uuid', uuid)

  return fetch.post(`${preUrl}/submit_signature_from_webpage`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Secret': window.__RUNTIME_CONFIG__.SECRETKEY
    },
    timeout: 15 * 1000
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadEsign
}
