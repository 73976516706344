import React, { Suspense, useEffect, useState } from 'react'
import './index.less'

import { Layout, theme } from 'antd'
import { Navigate, Outlet, useLocation } from 'react-router'
import HeaderComponent from './header'
import MenuComponent from './menu'
import { menuList } from './data'
import { getFirstPathCode } from 'utils/getFirstPathCode'
import { siteAuthConfig } from 'router/siteConfig'
import { useSelector } from 'react-redux'
import { RootState } from 'globalStore'

const { Sider, Content } = Layout

const App: React.FC = () => {
  const location = useLocation()
  const [openKey, setOpenkey] = useState<string>()
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname)
  const [collapsed, setCollapsed] = useState(false)
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

  useEffect(() => {
    const code = getFirstPathCode(location.pathname)

    setOpenkey(code)
    setSelectedKey(location.pathname)
  }, [location.pathname])

  const toggle = () => setCollapsed(!collapsed)

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  if (!isAuthenticated) {
    return <Navigate to={siteAuthConfig.routes.login.path} />
  }

  return (
    <Layout className='layout-page'>
      <HeaderComponent collapsed={collapsed} toggle={toggle} />
      <Layout>
        <Sider
          className='layout-page-sider'
          trigger={null}
          collapsible
          style={{ backgroundColor: colorBgContainer }}
          collapsedWidth={80}
          collapsed={collapsed}
          breakpoint='md'
        >
          <MenuComponent
            menuList={menuList}
            openKey={openKey}
            onChangeOpenKey={k => setOpenkey(k)}
            selectedKey={selectedKey}
            onChangeSelectedKey={k => setSelectedKey(k)}
          />
        </Sider>
        <Content className='layout-page-content'>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  )
}

export default App
