import { useRequest } from 'ahooks'
import { Button, Result, Modal, Avatar } from 'antd'
import { uploadEsign } from 'api/esign'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import styled from 'styled-components'
import logo from 'assets/icon/certis_logo.png'
import MediaQuery, { useMediaQuery } from 'react-responsive'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #bfbfbf;

  .sigCanvas {
    width: 100%;
    height: 100%;
    background: white;
  }
  .header {
    display: flex;
    background: white;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }
`
const SignaturePage: React.FC<any> = props => {
  const sigRef = React.useRef<SignatureCanvas>()
  const containterRef = React.useRef(null)
  // const size = useSize(containterRef)
  const { uuid } = useParams<'uuid'>()
  const req = useRequest<any, [File]>(
    file => {
      return uploadEsign(uuid!, file)
    },
    {
      manual: true
    }
  )
  const isBigScreen = useMediaQuery({ minWidth: 800 })
  if (!uuid || (!req.loading && req.error)) return <Result status='warning' title={req.error?.message} />

  if (!req.loading && req.data) return <Result status='success' title='Submit your E-sign Successfully!' />

  return (
    <Wrapper>
      <Helmet>Signature</Helmet>
      <div className='header'>
        <div className='centered-content' style={{ width: 150 }}>
          <Avatar src={logo} style={{ width: 200, height: 45 }} size={45} shape='square' />
        </div>
        <h3 style={{ transform: isBigScreen ? 'translateX(-50%)' : undefined, minWidth: 300 }}>
          Please sign in pad below and click Submit
        </h3>
        <MediaQuery minWidth={800}>
          <div />
        </MediaQuery>
        {/* <div style={{width: 22}} /> */}
      </div>
      <div
        ref={containterRef}
        style={{
          background: '#bfbfbf',
          width: 'calc(100% - 24px)',
          height: 'calc(100vh - 125px)',
          margin: '8px auto 12px auto',
          border: 'solid 2px black'
        }}
      >
        <SignatureCanvas
          ref={sigRef as any}
          penColor='black'
          minWidth={2}
          // clearOnResize={false}
          backgroundColor='white'
          canvasProps={{ className: 'sigCanvas' }}
        />
      </div>
      <div style={{ height: 'auto', display: 'flex', gap: 12, margin: '0 12px 12px 12px' }}>
        <Button
          block
          htmlType='button'
          type='primary'
          loading={req.loading}
          onClick={() => {
            Modal.confirm({
              title: 'Are you sure want to submit?',

              onOk: () => {
                const canvas = sigRef.current!.getTrimmedCanvas()
                canvas.toBlob(blob => {
                  const file = new File([blob!], 'esign.jpg', { type: 'image/jpeg' })
                  req.run(file)
                }, 'image/jpeg')
              }
            })
          }}
        >
          Submit
        </Button>
        <Button
          block
          htmlType='button'
          onClick={() => {
            sigRef.current?.clear()
          }}
        >
          Clear
        </Button>
      </div>
    </Wrapper>
  )
}

export default SignaturePage
