import React from 'react'
import path from 'path-browserify'

//auth-home
const LoginPage = React.lazy(() => import('pages/Auth/LoginPage'))
const CallbackPage = React.lazy(() => import('pages/Auth/CallbackPage'))

// Tracking
const TrackingPage = React.lazy(() => import('pages/TrackingPage'))

const UserPageNew = React.lazy(() => import('pages/UserNew'))
const Platform = React.lazy(() => import('pages/Platform'))
const Company = React.lazy(() => import('pages/Company'))

export const siteAuthConfig = {
  path: '/auth',
  routes: {
    login: {
      path: '/auth/login',
      name: 'Login',
      component: LoginPage
    },
    callback: {
      path: '/auth/callback',
      name: 'Callback',
      component: CallbackPage
    }
  }
}

export interface IRouteConfig {
  path: string
  index?: boolean
  component?: JSX.Element | React.FC<{}>
  routes?: {
    [key: string]: IRouteConfig
  }
}

export const routerConfig = {
  path: 'tracking',
  routes: {
    home: {
      path: '',
      name: 'Tracking',
      component: <TrackingPage />
    }
  }
}

export const freshworkConfig = {
  path: 'dashboard',
  routes: {
    dashboard: {
      path: '',
      name: 'Dashboard',
      component: <UserPageNew />
    },
    user: {
      path: 'user',
      name: 'User',
      component: <UserPageNew />
    },
    platform: {
      path: 'platform',
      name: 'Platform',
      component: <Platform />
    },
    company: {
      path: 'company',
      name: 'Company',
      component: <Company />
    }
  }
}

// export function mergePathClone(obj: any, parentPath: string = '/') {
//   const result: any = {}
//   for (var key in obj) {
//     const routeItem = obj[key]

//     const pathNow = path.join(parentPath, routeItem.path).replaceAll('.', '')
//     result[key] = {
//       path: pathNow
//     }

//     if (routeItem.routes) {
//       result[key].routes = mergePathClone(routeItem.routes, pathNow)
//     }
//   }

//   return result
// }

// export const routerPathConfig = mergePathClone(routerConfig, '/') as typeof routerConfig
