import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { siteAuthConfig, routerConfig, freshworkConfig } from './siteConfig'

import AuthLayout from 'layouts/AuthLayout'
import MasterLayout from 'layouts/MasterLayout'
import React from 'react'
import SignaturePage from 'pages/SignaturePage'
import FreshworkLayout from 'layouts/FreshworkLayout'

const generateRoutes = (props: any) => {
  return (
    <Route key={props.path} path={props.path} index={props.index} element={props.component}>
      {props.routes &&
        Object.keys(props.routes).map(key => {
          const item = props.routes[key]
          return generateRoutes(item)
        })}
    </Route>
  )
}
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='callback' element={<NavigateAuthCallback />} /> */}
        <Route path='signature/:uuid' index element={<SignaturePage />} />

        <Route path={siteAuthConfig.path} element={<AuthLayout />}>
          {Object.keys(siteAuthConfig.routes).map((key: any) => {
            // @ts-ignore
            const item = siteAuthConfig.routes[key]

            return (
              <Route key={item.path.replace(siteAuthConfig.path, '')} path={item.path} element={<item.component />} />
            )
          })}
        </Route>

        <Route path={routerConfig.path} element={<MasterLayout />}>
          {Object.keys(routerConfig.routes).map(key => {
            // @ts-ignore
            const item = routerConfig.routes[key]
            return generateRoutes(item)
          })}
        </Route>

        <Route path={freshworkConfig.path} element={<FreshworkLayout />}>
          {Object.keys(freshworkConfig.routes).map(key => {
            // @ts-ignore
            const item = freshworkConfig.routes[key]
            return generateRoutes(item)
          })}
        </Route>

        <Route path='*' element={<Navigate to='/dashboard' replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
